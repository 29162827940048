import React, { useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import './Header.css';
import logo from '../../Media/Logo.png';
import Button from '../Common/Button/Button';
import { CurrentLangContext } from '../../Utils/contexts/CurrentLanguageContext';
import { localization } from '../../Utils/Localization'

const Header = ({ contacts }) => {
  const { lng, setCurrentLang } = useContext(CurrentLangContext);
  // console.log(lng)
  return (
    <>
      <header className={`header`}>
        <Link to={'/'} style={{ marginTop: 0 }}>
          <img className='header__logo' src={logo} alt='logo' />
        </Link>
        <div className='header__tabs'>
          <nav className='header__navtabs'>
            <NavLink to="/aboutUs" end className={({ isActive }) =>
              isActive ? 'header__tab activated' : 'header__tab'
            }>{localization[lng].about_us1}</NavLink>
            <NavLink to="/services" className={({ isActive }) =>
              isActive ? 'header__tab activated' : 'header__tab'
            }>{localization[lng].services1}</NavLink>
            <NavLink to="/projects" className={({ isActive }) =>
              isActive ? 'header__tab activated' : 'header__tab'
            }>{localization[lng].projects1}</NavLink>
            <NavLink to="/insights"
              className={({ isActive }) =>
                isActive ? 'header__tab activated' : 'header__tab'
              }>{localization[lng].insights1}</NavLink>
          </nav>
          <a href='https://hh.ru/employer/1272' target={'_blank'} rel="noreferrer" className='header__tab' >{localization[lng].vacancies}</a>
          <div className='header__tab_language'>
            <div className={` ${lng === 'en' ? 'active_language' : ''}`} onClick={() => setCurrentLang('en')}>EN</div> / <div className={` ${lng === 'ru' ? 'active_language' : ''}`} onClick={() => setCurrentLang('ru')}>RUS</div>
          </div>
        </div>
        <a href={`mailto:${contacts.email}`} style={{ marginTop: 0 }}>
          <Button buttonText={localization[lng].contact_us} colored header />
        </a>
      </header>

    </>
  )
}
export default Header
